@use '@angular/material' as mat;
@use '../../node_modules/matcha-theme/main.scss' as matcha;
@include mat.core();

@mixin matcha-stepper($theme) {
    $primary: map-get($theme, primary);
    $primary-contrast: map-get($primary, 'contrast');
    $accent: map-get($theme, accent);
    $accent-contrast: map-get($accent, 'contrast');
    $warn: map-get($theme, warn);
    $warn-contrast: map-get($warn, 'contrast');

    $background: map-get($theme, background);
    $foreground: map-get($theme, foreground);

    // Stepper
    .mat-stepper-horizontal {
        &.bullet-stepper-header-labeled {
            .mat-horizontal-stepper-header-container {
                justify-content: end;
                margin-bottom: 0;
                margin-top: 0;
                margin-right: 0;
                padding-right: 24px;
                height: 0;
            }
        }

        &.bullet-stepper-header-labeled,
        &.bullet-stepper-header {
            position: relative;

            .mat-horizontal-stepper-header-container {
                .mat-stepper-horizontal-line {
                    display: none;
                }

                .mat-step-header-ripple {
                    display: none;
                }

                .mat-horizontal-stepper-header {
                    height: initial;
                    overflow: initial;

                    &.mat-step-header {
                        padding: 0;
                        border-radius: 0;
                        flex-direction: column;
                        position: inherit;
                        width: 40px;

                        &.cdk-keyboard-focused,
                        &.cdk-program-focused,
                        &:hover {
                            background-color: transparent;
                        }

                        .mat-step-icon {
                            margin: -32px 0 0 8px;
                            border-radius: 8px;
                            height: 8px;
                            width: 32px;
                            flex-shrink: 0;
                            position: absolute;
                        }
                    }

                    .mat-step-label {
                        position: absolute;
                        display: none;
                        right: 0;
                        margin-top: -52px;
                        margin-right: 24px;
                        // font-family: "CircularStd-Bold";
                        font-weight: 700;
                        color: map-get($foreground, label);
                    }

                    .mat-step-label.mat-step-label-active {
                        display: none;
                    }

                    .mat-step-label.mat-step-label-active.mat-step-label-selected {
                        display: flex;
                        justify-content: flex-end;
                    }

                    .mat-step-icon-state-edit {
                        margin: 0;
                    }

                    .mat-step-icon-state-edit.mat-step-icon {
                        background-color: map-get($primary, default);

                        .mat-icon {
                            display: none;
                        }
                    }

                    .mat-step-icon-state-number {
                        // background-color: blue;
                    }

                    .mat-step-icon-state-number.mat-step-icon {
                        background-color: map-get($background, disabled);

                        .mat-step-icon-content {
                            display: none;
                        }
                    }

                    .mat-step-icon-state-number.mat-step-icon.mat-step-icon-selected {
                        background-color: map-get($primary, default);
                        order: 1;
                    }
                }
            }

            .mat-horizontal-content-container {
                padding: 0;

                .mat-dialog-content {
                    max-height: calc(100vh - 268px);
                }
            }
        }

        &.hide-stepper-header {
            .mat-horizontal-stepper-header-container {
                display: none !important;
            }

            .mat-horizontal-content-container {
                overflow: hidden;
                padding: 0;
            }
        }

        &.page-layout-stepper {
            .mat-horizontal-stepper-header-container {
                padding-right: 24px;

                .mat-horizontal-stepper-header {
                    .mat-step-label {
                        margin-top: -60px;
                        margin-right: 24px;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 600px) {
    }
    @media screen and (min-width: 1024px) {
        .mat-stepper-horizontal {
            &.bullet-stepper-header-labeled {
                .mat-horizontal-stepper-header-container {
                    padding-right: 72px;
                    .mat-horizontal-stepper-header {
                        .mat-step-label {
                            margin-right: 72px;
                        }
                    }
                }
            }
        }
    }
    @media screen and (min-width: 1440px) {
    }
    @media screen and (min-width: 1920px) {
    }
}

//MATCHA THEME - LIGHT
// palette
$default-light-primary: matcha.palette(matcha.$blue-grey, 800, A400, 900); // Primary color
$default-light-accent: matcha.palette(matcha.$blue, 500, 400, 800); // Accent color
$default-light-warn: matcha.palette(matcha.$red, 900, 200, 900);
// typography
$matcha-default-typography: matcha.matcha-typography-config(
    $font-family: 'CircularStd, sans-serif, arial',
);
$mat-default-typography: mat.define-typography-config(
    $font-family: 'CircularStd, sans-serif, arial',
);
// theme
$matcha-default-theme: matcha.light-theme($default-light-primary, $default-light-accent, $default-light-warn);
$mat-default-theme: mat.define-light-theme(
    (
        color: (
            primary: $default-light-primary,
            accent: $default-light-accent,
            warn: $default-light-warn,
        ),
        typography: $mat-default-typography,
    )
);

.theme-default-light {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($mat-default-theme);
    @include matcha.matcha-components($matcha-default-theme);
    @include matcha.matcha-typography($matcha-default-typography);
    @include matcha-stepper($matcha-default-theme);
}

//MATCHA THEME - DARK
// palette
$default-dark-primary: matcha.palette(matcha.$blue-grey, 400, 50, 200); // Primary color
$default-dark-accent: matcha.palette(matcha.$blue, A400, A200, A700); // Accent color
$default-dark-warn: matcha.palette(matcha.$red, 200, 50, 300);
// typography
$matcha-default-typography: matcha.matcha-typography-config(
    $font-family: 'CircularStd, sans-serif, arial',
);
$mat-default-typography: mat.define-typography-config(
    $font-family: 'CircularStd, sans-serif, arial',
);
// theme
$matcha-default-theme: matcha.dark-theme($default-dark-primary, $default-dark-accent, $default-dark-warn);
$mat-default-theme: mat.define-dark-theme(
    (
        color: (
            primary: $default-dark-primary,
            accent: $default-dark-accent,
            warn: $default-dark-warn,
        ),
        typography: $mat-default-typography,
    )
);

.theme-default-dark {
    // Create an Angular Material theme from the $theme map
    @include mat.all-component-themes($mat-default-theme);
    @include matcha.matcha-components($matcha-default-theme);
    @include matcha.matcha-typography($matcha-default-typography);
    @include matcha-stepper($matcha-default-theme);
}


.mat-mdc-snack-bar-container.success {
    .mdc-snackbar__surface {
        background: #119046 !important;
        color: #ffffff !important;
    }
}

.mat-mdc-snack-bar-container.error {
    .mdc-snackbar__surface {
        background: #e53a3a !important;
        color: #ffffff !important;
    }
}

.mat-mdc-dialog-container {
    height: auto !important;
}
