@import 'app/app.theme.scss';

body {
    margin: 0;
    font-family: 'CircularStd-Regular, sans-serif, arial';
}

button[disabled] {
    cursor: not-allowed;
    opacity: 0.7;
}

button:not([disabled]) {
    cursor: pointer;
}

// .button-black {
//     background-color: #262728;
//     color: #fff;
//     text-align: center;
//     cursor: pointer;
//     line-height: normal !important;
//     padding: 0 !important;
// }

@media only screen and (max-width: 600px) {
    .w-xs-100-p {
        width: 100% !important;
    }
}

.pointer {
    cursor: pointer !important;
}

// @keyframes blink {
//     0% {
//         opacity: 1;
//     }
//     50% {
//         opacity: 0.5;
//     }
//     100% {
//         opacity: 1;
//     }
// }

// .card-blink {
//     animation: blink 1s infinite;
// }

// .mdc-snackbar {
//     text-align: center;
//     p {
//         font-size: 18px !important;
//         margin-bottom: 0;
//     }
//     &.error {
//         .mdc-snackbar__surface {
//             background-color: #e53a3a !important;
//         }
//     }
//     &.success {
//         .mdc-snackbar__surface {
//             background-color: #86bd00 !important;
//         }
//     }
// }

// .mat-toolbar {
//     &.mat-toolbar-single-row {
//         background-color: #86bd00;
//     }
// }

// .bebas-neue-regular {
//     font-family: 'Bebas Neue', sans-serif !important;
//     font-weight: 400;
//     font-style: normal;
// }

// mat-toolbar {
//     background-color: #222526 !important;
// }
